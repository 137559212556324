import { DatasetPersistance } from "../repository/dataset.repository";
import { IDatasetService } from "../service/dataset.service.interface";

export class DatasetService implements IDatasetService {
  persistance: DatasetPersistance;

  constructor() {
    this.persistance = new DatasetPersistance();
  }

  getFilteredDatasetData = (data: any, filter: string) => {
    if (!data || !data.data) {
      return null;
    }

    return {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        title: item.title.split(filter)[0], // EX: "_SHIPD"
      })),
    };
  };

  getPoints = async (title: string) => {
    return this.persistance.getPoints(title);
  };
}

export const datasetService = new DatasetService();
