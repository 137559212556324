import * as L from "leaflet";
import {
  IBaseLeafletParameters,
  IExtendedLeafletParameters,
} from "../interfaces/leaflet.service.interface";

class LeafletService {
  R = 6371.0;
  COST_PER_10_KM2 = 0.005;

  getLatLng = ({
    startX,
    startY,
    endX,
    endY,
  }: {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
  }) => {
    // console.log(startX, startY, endX, endY);
    const upperLeftLat = Math.max(startX, endX).toString();
    const lowerRightLat = Math.min(startX, endX).toString();
    const upperLeftLng = Math.min(startY, endY).toString();
    const lowerRightLng = Math.max(startY, endY).toString();

    return {
      upperLeftLat,
      lowerRightLat,
      upperLeftLng,
      lowerRightLng,
    };
  };

  getPolygonCoords = (image: string[]) =>
    image.map((coord) => {
      const [lon, lat] = coord
        .replace("POLYGON((", "")
        .replace("))", "")
        .split(" ")
        .map(Number);

      return [lat, lon];
    });

  toRadians = (degree: number) => (degree * Math.PI) / 180;

  calculateAreaKM2 = (coordinates: {
    leftTop: { lat: number; lng: number };
    rightBottom: { lat: number; lng: number };
  }) => {
    // 라디안으로 변환하는 함수

    // 주어진 좌표를 라디안으로 변환
    const lat1 = this.toRadians(coordinates.leftTop.lat);
    const lng1 = this.toRadians(coordinates.leftTop.lng);
    const lat2 = this.toRadians(coordinates.rightBottom.lat);
    const lng2 = this.toRadians(coordinates.rightBottom.lng);

    // 위도와 경도의 차이
    const dLat = lat2 - lat1;
    const dLng = lng2 - lng1;

    // 두 위도점 사이의 거리 계산
    const aLat =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) ** 2;
    const cLat = 2 * Math.atan2(Math.sqrt(aLat), Math.sqrt(1 - aLat));
    const distanceLat = this.R * cLat;

    // 평균 위도에서의 두 경도점 사이의 거리 계산
    const latMid = (lat1 + lat2) / 2;
    const aLng = Math.cos(latMid) * Math.sin(dLng / 2) ** 2;
    const cLng = 2 * Math.atan2(Math.sqrt(aLng), Math.sqrt(1 - aLng));
    const distanceLng = this.R * cLng;

    // 면적 계산 (km^2)
    return distanceLat * distanceLng;
  };

  // 전체 면적에 대한 비용 계산
  calculateCostByKM2 = (areaKM2: number) =>
    (areaKM2 / 10) * this.COST_PER_10_KM2;

  calculateOverlapPercentage = (
    rect1: {
      leftTop: { lat: number; lng: number };
      rightBottom: { lat: number; lng: number };
    },
    rect2: {
      leftTop: { lat: number; lng: number };
      rightBottom: { lat: number; lng: number };
    }
  ) => {
    const overlapLeftTop = {
      lat: Math.min(rect1.leftTop.lat, rect2.leftTop.lat),
      lng: Math.max(rect1.leftTop.lng, rect2.leftTop.lng),
    };
    const overlapRightBottom = {
      lat: Math.max(rect1.rightBottom.lat, rect2.rightBottom.lat),
      lng: Math.min(rect1.rightBottom.lng, rect2.rightBottom.lng),
    };

    // 겹치는 영역이 없는 경우
    if (
      overlapLeftTop.lat < overlapRightBottom.lat ||
      overlapLeftTop.lng > overlapRightBottom.lng
    ) {
      return null;
    }

    // 겹치는 영역의 면적 계산
    const overlapArea =
      (overlapLeftTop.lat - overlapRightBottom.lat) *
      (overlapRightBottom.lng - overlapLeftTop.lng);

    // 두 직사각형의 면적 계산
    const area1 =
      (rect1.leftTop.lat - rect1.rightBottom.lat) *
      (rect1.rightBottom.lng - rect1.leftTop.lng);
    const area2 =
      (rect2.leftTop.lat - rect2.rightBottom.lat) *
      (rect2.rightBottom.lng - rect2.leftTop.lng);

    // 두 직사각형 중 작은 면적 찾기
    const smallerArea = Math.min(area1, area2);

    // 겹치는 영역의 백분율 계산
    return (overlapArea / smallerArea) * 100;
  };

  findBounds = (coords: number[][]) => {
    const leftTop = {
      lat: -Infinity,
      lng: Infinity,
    };
    const rightBottom = {
      lat: Infinity,
      lng: -Infinity,
    };

    coords.forEach(([lat, lng]) => {
      if (lat > leftTop.lat) leftTop.lat = lat;
      if (lng < leftTop.lng) leftTop.lng = lng;
      if (lat < rightBottom.lat) rightBottom.lat = lat;
      if (lng > rightBottom.lng) rightBottom.lng = lng;
    });

    return { leftTop, rightBottom };
  };

  setViewByImageBounds = (p: IExtendedLeafletParameters) => {
    const imageId = this.#getFirstFrameAndTrack(p);
    const fitBoundOptions: L.FitBoundsOptions = {
      padding: [-20, -20],
    };

    if (p.selectedImages[imageId].length > 0) {
      const boundsArr = p.selectedImages[imageId].map((img) => img.imageBounds);
      const imageBoundes = L.latLngBounds(boundsArr);

      const center = imageBoundes.getCenter(); // 이미지 경계의 중심점을 얻습니다.
      const currentMap = p.map;
      // console.log(center, currentMap.getZoom());

      // currentMap.setView(center, currentMap.getZoom()); // 중심점으로 지도 뷰를 설정합니다.
      currentMap.fitBounds(imageBoundes, fitBoundOptions);
    }
  };

  #getFirstFrameAndTrack = ({ firstFrame, track }: IBaseLeafletParameters) => {
    return `${firstFrame}-${track}`;
  };
}

export const leafletService = new LeafletService();
