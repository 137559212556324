import { SentinelSearchParamModel } from "../entity/sentinel-search-param.entity";

export class CommonService {
  constructor() {}

  isValidQueryString(queryParams: string) {
    if (!queryParams || queryParams.trim() === "") {
      return false;
    }

    const params = new URLSearchParams(queryParams);

    // SentinelSearchParamModel의 인스턴스 생성
    const sentinelSearchParam = new SentinelSearchParamModel();

    // Object.keys를 사용하여 프로퍼티 키를 가져옴
    const properties = Object.keys(sentinelSearchParam);

    for (const prop of properties) {
      const value = params.get(prop);
      // console.log(prop, value);
      if (value === "NaN") {
        // console.error(`Invalid value for ${prop}: NaN`);
        return false;
      }
    }

    return true;
  }
}
