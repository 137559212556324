import { MyDataPersistance } from "../repository/my-data.repository";

export class MyDataService {
  private persistance: MyDataPersistance;

  constructor(private readonly accessToken: string | undefined) {
    this.persistance = new MyDataPersistance(accessToken);
  }

  async getAllCategories() {
    return await this.persistance.getAllCategories();
  }
}
