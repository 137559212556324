import { useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { format, parse, subDays } from "date-fns";

const useCalendarLogic = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const parseDate = (dateString: string | null): Date | null => {
    return dateString ? parse(dateString, "yyyy-MM-dd", new Date()) : null;
  };

  const startDate = parseDate(searchParams.get("startDate"));
  const endDate = parseDate(searchParams.get("endDate"));

  const updateSearchParams = (params: { [key: string]: string | null }) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    Object.entries(params).forEach(([key, value]) => {
      if (value === null) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value);
      }
    });
    router.replace(`${pathname}?${newSearchParams.toString()}`, {
      scroll: false,
    });
  };

  const handleDateChange = (
    newStartDate: Date | null,
    newEndDate: Date | null,
  ) => {
    updateSearchParams({
      startDate: newStartDate ? format(newStartDate, "yyyy-MM-dd") : null,
      endDate: newEndDate ? format(newEndDate, "yyyy-MM-dd") : null,
    });
  };
  //
  // const handleReset = () => {
  //   updateSearchParams({ startDate: null, endDate: null });
  // };

  useEffect(() => {
    if (!searchParams.has("startDate") && !searchParams.has("endDate")) {
      const today = new Date();
      updateSearchParams({
        startDate: format(subDays(today, 7), "yyyy-MM-dd"),
        endDate: format(today, "yyyy-MM-dd"),
      });
    }
  }, [searchParams]);

  return { startDate, endDate, handleDateChange /*, handleReset */ };
};

export default useCalendarLogic;
