export class DatasetPersistance {
  baseUrl = `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}/api/satellites/ship-position`;

  async getPoints(filter: string) {
    try {
      const url = `${this.baseUrl}?image=${filter}`;

      const response = await fetch(url);

      if (response.ok) {
        return await response.json();
      } else {
        throw new Error("Failed to fetch data!");
      }
    } catch (error) {
      console.error("DatasetPersistance:", error);
      return null;
    }
  }
}
