// TODO: Sentinel이 공용화 되어야 하는가?
import { Sentinel } from "../../../../apps/platform/src/core/entities/models/sentinel";
// import { SentinelData } from "@/common/interfaces/data.interface";
import { parsedSatelliteFileName } from "../interfaces/request.interface";

class SatelliteService {
  // eslint-disable-next-line
  constructor() {}

  getLatLng = ({
    startX,
    startY,
    endX,
    endY,
  }: {
    startX: string | number;
    startY: string | number;
    endX: string | number;
    endY: string | number;
  }) => {
    const safeParseFloat = (value) => {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? 0 : parsed;
    };
    // console.log(startX, startY, endX, endY);

    const upperLeftLat = Math.max(
      safeParseFloat(startX),
      safeParseFloat(endX)
    ).toString();
    const lowerRightLat = Math.min(
      safeParseFloat(startX),
      safeParseFloat(endX)
    ).toString();
    const upperLeftLng = Math.min(
      safeParseFloat(startY),
      safeParseFloat(endY)
    ).toString();
    const lowerRightLng = Math.max(
      safeParseFloat(startY),
      safeParseFloat(endY)
    ).toString();

    return {
      upperLeftLat,
      lowerRightLat,
      upperLeftLng,
      lowerRightLng,
    };
  };

  getFirstFrameAndTrack = (firstFrame, track) => {
    if (firstFrame !== null && track !== null) {
      return `${firstFrame}-${track}`;
    } else {
      throw new Error("firstFrame and track are not defined");
    }
  };

  uniqueDataWithThumbnail = (p: Sentinel[]): Sentinel[] => {
    return p
      ?.filter((item) => item.thumbnailUrl)
      .filter(
        (value, idx, arr) =>
          arr.findIndex(
            (t) => t.firstFrame === value.firstFrame && t.track === value.track
          ) === idx
      )
      .sort(
        (a, b) =>
          new Date(b.processingDate).getTime() -
          new Date(a.processingDate).getTime()
      );
  };

  relatedDataWithThumbnail = (data, searchParams) => {
    const { firstFrame, track } = searchParams;

    return data
      .filter((item) => item.firstFrame === parseInt(firstFrame))
      .filter((item) => item.track === parseInt(track))
      .map((item) => {
        const parsedSatelliteFileName = this.parseSatelliteFileName(
          item.product_file_id
        );

        const isMetadata =
          parsedSatelliteFileName.processingType.includes("METADATA");

        return {
          ...item,
          isMetadata,
        };
      })
      .sort((a, b) => {
        // 첫 번째로, startDate를 기준으로 내림차순 정렬
        const dateComparison = b.startDate.localeCompare(a.startDate);
        if (dateComparison !== 0) {
          return dateComparison;
        }

        // 같은 날짜 내에서 METADATA는 항상 마지막에 위치
        if (a.isMetadata && !b.isMetadata) {
          return 1;
        }
        if (!a.isMetadata && b.isMetadata) {
          return -1;
        }

        return 0; // 동일한 날짜이고 둘 다 METADATA가 아닌 경우, 순서를 변경하지 않음
      });
  };

  parseSatelliteFileName = (fileName: string): parsedSatelliteFileName => {
    const lastDashIndex = fileName.lastIndexOf("-");

    return {
      missionIdentifier: fileName.slice(0, 3),
      beamMode: fileName.slice(4, 6),
      productType: fileName.slice(7, 10),
      resolution: fileName.slice(10, 11),
      processingLevel: fileName.slice(12, 13),
      productClass: fileName.slice(13, 14),
      polarization: fileName.slice(14, 16),
      startDate: fileName.slice(17, 32),
      stopDate: fileName.slice(33, 48),
      absoluteOrbit: fileName.slice(49, 55),
      missionDataTakeId: fileName.slice(56, 62),
      productUniqueId: fileName.slice(63, lastDashIndex),
      processingType: fileName.slice(lastDashIndex + 1), // Capture everything after the last dash
    };
  };

  filterNonGRDData = (data: Sentinel[]) => {
    return data?.filter(() => {
      return true;
    });
  };

  filterGRDData = (data: Sentinel[]) => {
    return data?.filter((item) => {
      const fileInfo = this.parseSatelliteFileName(item.product_file_id);
      // GRD 데이터 중에서 METADATA를 포함하지 않는 경우만 필터링
      if (fileInfo.productType === "GRD") {
        return !fileInfo.processingType.includes("METADATA");
      }
      // 그 외의 모든 데이터는 포함
      return false;
    });
  };

  groupedData = (data: Sentinel[]) => {
    // console.log(data);
    const nonGRDData = this.filterNonGRDData(data);
    const GRDData = this.filterGRDData(data);
    // console.log("GRDData", GRDData);
    // console.log("nonGRDData", nonGRDData);

    return GRDData?.reduce(
      (
        acc: Record<string, { grdData: Sentinel[]; nonGrdData: Sentinel[] }>,
        grdData: Sentinel
      ) => {
        const groupID = grdData.groupID;
        if (!acc[groupID]) {
          acc[groupID] = {
            grdData: [],
            nonGrdData: [],
          };
        }
        acc[groupID].grdData.push(grdData);

        const relatedNonGrdData = nonGRDData.filter(
          (nongrd) => nongrd.groupID === groupID
        );
        acc[groupID].nonGrdData.push(...relatedNonGrdData);

        return acc;
      },
      {}
    );
  };
}

export const satelliteService = new SatelliteService();
