import { BaseSearchParamModel } from '@repo/common/entity'

import {
  ICalculatedBox,
  IExtendedSearchParam,
} from '@/common/interfaces/request.interface'

export class SearchParamDto extends BaseSearchParamModel {
  makeSearchParamDtoWithCalculatedBoxAndSearchParams(
    calculatedBox?: ICalculatedBox,
    searchParams?: IExtendedSearchParam,
    pageSize?: number,
    page?: number
  ): string {
    const newDto = new SearchParamDto()

    if (calculatedBox) {
      newDto.upperLeftLat = calculatedBox.upperLeftLat
      newDto.lowerRightLat = calculatedBox.lowerRightLat
      newDto.upperLeftLng = calculatedBox.upperLeftLng
      newDto.lowerRightLng = calculatedBox.lowerRightLng
    }

    if (searchParams) {
      const { ...filteredSearchParams } = searchParams

      newDto.startDate = filteredSearchParams.startDate
      newDto.endDate = filteredSearchParams.endDate
      newDto.beamMode = filteredSearchParams.beamMode
      newDto.polarization = filteredSearchParams.polarization.replace(
        /\s+/g,
        ''
      )
      newDto.flightDirection = filteredSearchParams.flightDirection
    }

    // TODO: ROI 범위에 따라 pageSize를 조절하는 로직이 필요.
    newDto.pageSize = pageSize?.toString() || '1000'
    newDto.page = page?.toString() || '1'

    return new URLSearchParams({ ...newDto }).toString()
  }
}
