"use client";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";

import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

export function DatePickerWithRange({
  className,
  onDateChange,
  initialStartDate,
  initialEndDate,
}: React.HTMLAttributes<HTMLDivElement> & {
  onDateChange: (startDate: Date | null, endDate: Date | null) => void;
  initialStartDate: Date | null;
  initialEndDate: Date | null;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [tempDate, setTempDate] = useState<DateRange | undefined>(
    initialStartDate && initialEndDate
      ? { from: initialStartDate, to: initialEndDate }
      : undefined
  );
  const [date, setDate] = useState<DateRange | undefined>(
    initialStartDate && initialEndDate
      ? { from: initialStartDate, to: initialEndDate }
      : undefined
  );

  useEffect(() => {
    if (initialStartDate && initialEndDate) {
      setDate({ from: initialStartDate, to: initialEndDate });
      setTempDate({ from: initialStartDate, to: initialEndDate });
    }
  }, [initialStartDate, initialEndDate]);

  const handleSelect = (selectedDate: DateRange | undefined) => {
    setTempDate(selectedDate);
  };

  const handleConfirm = () => {
    setDate(tempDate);
    setIsOpen(false);
    if (tempDate?.from) {
      onDateChange(tempDate.from, tempDate.to || null);
    } else {
      onDateChange(null, null);
    }
  };

  const handlePopoverOpenChange = (open: boolean) => {
    if (!open) {
      handleConfirm();
    }
    setIsOpen(open);
  };

  // 현재 날짜 이후의 날짜를 비활성화하는 함수
  const disabledDays = { after: new Date() };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={isOpen} onOpenChange={handlePopoverOpenChange}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[357px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={tempDate?.from}
            selected={tempDate}
            onSelect={handleSelect}
            numberOfMonths={2}
            disabled={disabledDays}
          />
          <div className="p-3 border-t border-border flex justify-end">
            <Button onClick={handleConfirm} className="w-[100px]">
              Confirm
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
