export abstract class BaseSearchParamModel {
  upperLeftLat: string = "";
  lowerRightLat: string = "";
  upperLeftLng: string = "";
  lowerRightLng: string = "";
  startDate: string = "";
  endDate: string = "";
  beamMode: string = "";
  polarization: string = "";
  flightDirection: string = "";
  pageSize: string = "";
  page = "";
}
