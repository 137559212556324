import { DatePickerWithRange } from "@repo/ui/components/ui/date-picker-with-range";
import useCalendarLogic from "@/app/(main)/components/Calendar/(strategy)/hooks/useCalendarLogic";

const Calendar = () => {
  const { startDate, endDate, handleDateChange } = useCalendarLogic();

  return (
    <DatePickerWithRange
      initialStartDate={startDate}
      initialEndDate={endDate}
      onDateChange={handleDateChange}
    />
  );
};

export default Calendar;
