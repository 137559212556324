import { Category } from "@repo/common/dto/category";
import fetchAPI from "@repo/common/helpers/fetch";
import { ApiInnerResponse } from "!/interfaces";

export class MyDataPersistance {
  // TODO: platform 프로젝트에도 필요해서 common으로 복사함. 근데 원래 서버 컴포넌트에서 데이터 요청하는 방식이라 http 요청을 보내고 있음.

  BASE_URL = `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}`;

  constructor(private readonly accessToken: string | undefined) {}

  async getAllCategories() {
    try {
      const response = await fetchAPI<ApiInnerResponse<Category>>(
        `${this.BASE_URL}/api/my-data/categories/profile`,
        {},
        this.accessToken,
      );

      if (response.status === 200) {
        return response;
      } else {
        throw new Error("Failed to fetch all categories");
      }
    } catch (error) {
      console.error("MyDataPersistance:", error.message, error);
      throw new Error(error.message);
    }
  }
}
